import React from 'react'

import powerby from '../assets/img/footerlogo.png';
function Chat() {
  return (
    <>
    <p className='copyright'>Powered by <img src={powerby} alt="" /> </p>
    </>
  )
}

export default Chat